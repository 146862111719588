import { defineMessages } from 'react-intl';
import { MusicService } from './types';
import { nextAsset } from '../../nextAsset';

const { whatIsDescription } = defineMessages({
  whatIsDescription: {
    id: 'web.music-service.what-is.qq-music',
    defaultMessage:
      'QQ Music is a popular music streaming service in China. It offers millions of songs, music videos, and karaoke features. With a premium subscription, you can enjoy personalized recommendations, social features, and high-quality audio.'
  }
});
export const qqMusic: MusicService = {
  id: 'qq-music',
  shortName: 'QQ音乐',
  name: 'QQ Music (QQ 音乐)',
  isComingSoon: false,
  areStatsSupported: true,
  isAlbumTransferSupported: false,
  isSupported: false,
  color: '#fbdc4b',
  textColor: '#e4c73f',
  logo: {
    light: nextAsset('images/music-services/qq-light.svg'),
    dark: nextAsset('images/music-services/qq-dark.svg')
  },
  logoPadding: {
    light: nextAsset('images/music-services/qq-light-padding.svg'),
    dark: nextAsset('images/music-services/qq-dark-padding.svg')
  },
  logoColor: {
    light: nextAsset('images/music-services/qq-color-light.svg'),
    dark: nextAsset('images/music-services/qq-color-dark.svg')
  },
  logoColorPadding: {
    light: nextAsset('images/music-services/qq-color-light-padding.svg'),
    dark: nextAsset('images/music-services/qq-color-dark-padding.svg')
  },
  logoOnBackground: nextAsset('images/music-services/qq-color-dark.svg'),
  whatIsDescription
};
