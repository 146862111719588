import { defineMessages } from 'react-intl';
import { MusicService } from './types';
import { nextAsset } from '../../nextAsset';

const { whatIsDescription } = defineMessages({
  whatIsDescription: {
    id: 'web.music-service.what-is.qobuz',
    defaultMessage:
      'Qobuz is a music streaming service focused on high-fidelity and lossless audio quality. Explore an expertly curated library of 100 million tracks and discover new music with expert recommendations.'
  }
});
export const qobuz: MusicService = {
  id: 'qobuz',
  shortName: 'Qobuz',
  name: 'Qobuz',
  isComingSoon: false,
  areStatsSupported: true,
  isAlbumTransferSupported: true,
  isSupported: true,
  color: '#0070ef',
  logo: {
    light: nextAsset('images/music-services/qobuz-light.svg'),
    dark: nextAsset('images/music-services/qobuz-dark.svg')
  },
  logoPadding: {
    light: nextAsset('images/music-services/qobuz-light-padding.svg'),
    dark: nextAsset('images/music-services/qobuz-dark-padding.svg')
  },
  logoColor: {
    light: nextAsset('images/music-services/qobuz-color-light.svg'),
    dark: nextAsset('images/music-services/qobuz-color-dark.svg')
  },
  logoColorPadding: {
    light: nextAsset('images/music-services/qobuz-color-light-padding.svg'),
    dark: nextAsset('images/music-services/qobuz-color-dark-padding.svg')
  },
  logoOnBackground: nextAsset('images/music-services/qobuz-dark.svg'),
  whatIsDescription
};
