import { defineMessages } from 'react-intl';
import { MusicService } from './types';
import { nextAsset } from '../../nextAsset';

const { whatIsDescription } = defineMessages({
  whatIsDescription: {
    id: 'web.music-service.what-is.gaana',
    defaultMessage:
      'Gaana is a popular music streaming service in India. It offers over 45 million tracks of Bollywood, regional Indian music, and international hits. Listen on mobile devices and the web with free and premium plans.'
  }
});
export const gaana: MusicService = {
  id: 'gaana',
  shortName: 'Gaana',
  name: 'Gaana',
  isComingSoon: false,
  areStatsSupported: true,
  isAlbumTransferSupported: true,
  isSupported: false,
  color: '#e72c30',
  logo: {
    light: nextAsset('images/music-services/gaana-light.svg'),
    dark: nextAsset('images/music-services/gaana-dark.svg')
  },
  logoPadding: {
    light: nextAsset('images/music-services/gaana-light-padding.svg'),
    dark: nextAsset('images/music-services/gaana-dark-padding.svg')
  },
  logoColor: {
    light: nextAsset('images/music-services/gaana-color-light.svg'),
    dark: nextAsset('images/music-services/gaana-color-dark.svg')
  },
  logoColorPadding: {
    light: nextAsset('images/music-services/gaana-color-light-padding.svg'),
    dark: nextAsset('images/music-services/gaana-color-dark-padding.svg')
  },
  logoOnBackground: nextAsset('images/music-services/gaana-dark.svg'),
  whatIsDescription
};
