import { defineMessages } from 'react-intl';
import { MusicService } from './types';
import { nextAsset } from '../../nextAsset';

const { whatIsDescription } = defineMessages({
  whatIsDescription: {
    id: 'web.music-service.what-is.youtube',
    defaultMessage:
      "YouTube isn't strictly a music streaming service but offers a vast library of music videos and songs you can’t find anywhere else. You can create playlists, listen for free, and enjoy recommended content based on your preferences."
  }
});
export const youtube: MusicService = {
  id: 'youtube',
  shortName: 'YouTube',
  name: 'YouTube',
  isComingSoon: false,
  areStatsSupported: true,
  isAlbumTransferSupported: false,
  isSupported: true,
  color: '#FF0000',
  logo: {
    light: nextAsset('images/music-services/youtube-light.svg'),
    dark: nextAsset('images/music-services/youtube-dark.svg')
  },
  logoPadding: {
    light: nextAsset('images/music-services/youtube-light-padding.svg'),
    dark: nextAsset('images/music-services/youtube-dark-padding.svg')
  },
  logoColor: {
    light: nextAsset('images/music-services/youtube-color-light.svg'),
    dark: nextAsset('images/music-services/youtube-color-dark.svg')
  },
  logoColorPadding: {
    light: nextAsset('images/music-services/youtube-color-light-padding.svg'),
    dark: nextAsset('images/music-services/youtube-color-dark-padding.svg')
  },
  logoOnBackground: nextAsset('images/music-services/youtube-dark.svg'),
  whatIsDescription
};
