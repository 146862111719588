import { defineMessages } from 'react-intl';
import { MusicService } from './types';
import { nextAsset } from '../../nextAsset';

const { whatIsDescription } = defineMessages({
  whatIsDescription: {
    id: 'web.music-service.what-is.anghami',
    defaultMessage:
      'Anghami is a music streaming service popular in the Middle East and North Africa, offering millions of Arabic and international songs. Discover new music, create playlists, and listen on mobile devices and the web with free and premium plans.'
  }
});
export const anghami: MusicService = {
  id: 'anghami',
  shortName: 'Anghami',
  name: 'Anghami',
  isComingSoon: false,
  areStatsSupported: true,
  isAlbumTransferSupported: true,
  isSupported: true,
  color: '#8d00f2',
  logo: {
    light: nextAsset('images/music-services/anghami-light.svg'),
    dark: nextAsset('images/music-services/anghami-dark.svg')
  },
  logoPadding: {
    light: nextAsset('images/music-services/anghami-light-padding.svg'),
    dark: nextAsset('images/music-services/anghami-dark-padding.svg')
  },
  logoColor: {
    light: nextAsset('images/music-services/anghami-color-light.svg'),
    dark: nextAsset('images/music-services/anghami-color-dark.svg')
  },
  logoColorPadding: {
    light: nextAsset('images/music-services/anghami-color-light-padding.svg'),
    dark: nextAsset('images/music-services/anghami-color-dark-padding.svg')
  },
  logoOnBackground: nextAsset('images/music-services/anghami-color-dark.svg'),
  whatIsDescription
};
