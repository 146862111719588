import { defineMessages } from 'react-intl';
import { MusicService } from './types';
import { nextAsset } from '../../nextAsset';

const { whatIsDescription } = defineMessages({
  whatIsDescription: {
    id: 'web.music-service.what-is.sound-cloud',
    defaultMessage:
      'SoundCloud is a music streaming platform focusing on independent artists and music discovery. With 135+ million tracks, you can upload, share, and listen to a massive music library, remixes, and podcasts. Free and premium plans are available.'
  }
});
export const soundcloud: MusicService = {
  id: 'soundcloud',
  appId: 'sound-cloud',
  shortName: 'SoundCloud',
  name: 'SoundCloud',
  isComingSoon: false,
  areStatsSupported: true,
  isAlbumTransferSupported: true,
  isSupported: true,
  color: '#ff5500',
  logo: {
    light: nextAsset('images/music-services/soundcloud-light.svg'),
    dark: nextAsset('images/music-services/soundcloud-dark.svg')
  },
  logoPadding: {
    light: nextAsset('images/music-services/soundcloud-light-padding.svg'),
    dark: nextAsset('images/music-services/soundcloud-dark-padding.svg')
  },
  logoColor: {
    light: nextAsset('images/music-services/soundcloud-color-light.svg'),
    dark: nextAsset('images/music-services/soundcloud-color-dark.svg')
  },
  logoColorPadding: {
    light: nextAsset('images/music-services/soundcloud-color-light-padding.svg'),
    dark: nextAsset('images/music-services/soundcloud-color-dark-padding.svg')
  },
  logoOnBackground: nextAsset('images/music-services/soundcloud-dark.svg'),
  whatIsDescription
};
