import { defineMessages } from 'react-intl';
import { MusicService } from './types';
import { nextAsset } from '../../nextAsset';

const { whatIsDescription } = defineMessages({
  whatIsDescription: {
    id: 'web.music-service.what-is.vkmusic',
    defaultMessage:
      'VK Music is a streaming service integrated with the popular Russian social network VK. Users can listen to millions of songs, create playlists, and share music with friends. Free and premium plans are available.'
  }
});
export const vkMusic: MusicService = {
  id: 'vk-music',
  appId: 'vk',
  shortName: 'VK Music',
  name: 'VK Music (VKontakte Music / BOOM)',
  isComingSoon: false,
  areStatsSupported: true,
  isAlbumTransferSupported: false,
  isSupported: true,
  color: '#5181b8',
  logo: {
    light: nextAsset('images/music-services/vk-light.svg'),
    dark: nextAsset('images/music-services/vk-dark.svg')
  },
  logoPadding: {
    light: nextAsset('images/music-services/vk-light-padding.svg'),
    dark: nextAsset('images/music-services/vk-dark-padding.svg')
  },
  logoColor: {
    light: nextAsset('images/music-services/vk-color-light.svg'),
    dark: nextAsset('images/music-services/vk-color-dark.svg')
  },
  logoColorPadding: {
    light: nextAsset('images/music-services/vk-color-light-padding.svg'),
    dark: nextAsset('images/music-services/vk-color-dark-padding.svg')
  },
  logoOnBackground: nextAsset('images/music-services/vk-color-light.svg'),
  whatIsDescription
};
