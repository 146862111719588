import { defineMessages } from 'react-intl';
import { MusicService } from './types';
import { nextAsset } from '../../nextAsset';

const { whatIsDescription } = defineMessages({
  whatIsDescription: {
    id: 'web.music-service.what-is.deezer',
    defaultMessage:
      'Deezer is a music streaming service with over 90 million songs, podcasts, and radio channels. With a premium subscription, you can enjoy personalized recommendations, curated playlists, and high-fidelity lossless audio.'
  }
});
export const deezer: MusicService = {
  id: 'deezer',
  shortName: 'Deezer',
  name: 'Deezer',
  isComingSoon: false,
  areStatsSupported: true,
  isAlbumTransferSupported: true,
  isSupported: true,
  color: '#23232D',
  textColor: '#6b42b4',
  logo: {
    light: nextAsset('images/music-services/deezer-light.svg'),
    dark: nextAsset('images/music-services/deezer-dark.svg')
  },
  logoPadding: {
    light: nextAsset('images/music-services/deezer-light-padding.svg'),
    dark: nextAsset('images/music-services/deezer-dark-padding.svg')
  },
  logoColor: {
    light: nextAsset('images/music-services/deezer-color-light.svg'),
    dark: nextAsset('images/music-services/deezer-color-dark.svg')
  },
  logoColorPadding: {
    light: nextAsset('images/music-services/deezer-color-light-padding.svg'),
    dark: nextAsset('images/music-services/deezer-color-dark-padding.svg')
  },
  logoOnBackground: nextAsset('images/music-services/deezer-color-dark.svg'),
  whatIsDescription
};
