import { defineMessages } from 'react-intl';
import { MusicService } from './types';
import { nextAsset } from '../../nextAsset';

const { whatIsDescription } = defineMessages({
  whatIsDescription: {
    id: 'web.music-service.what-is.pandora',
    defaultMessage:
      'Pandora is a music streaming service with a personalized radio-style experience and a 30 million-strong catalog. Create stations based on your favorite artists or songs and discover new music through recommendations. Free and premium plans are available.'
  }
});
export const pandora: MusicService = {
  id: 'pandora',
  shortName: 'Pandora',
  name: 'Pandora',
  isComingSoon: false,
  areStatsSupported: true,
  isAlbumTransferSupported: false,
  isSupported: true,
  color: '#0089FC',
  logo: {
    light: nextAsset('images/music-services/pandora-light.svg'),
    dark: nextAsset('images/music-services/pandora-dark.svg')
  },
  logoPadding: {
    light: nextAsset('images/music-services/pandora-light-padding.svg'),
    dark: nextAsset('images/music-services/pandora-dark-padding.svg')
  },
  logoColor: {
    light: nextAsset('images/music-services/pandora-color-light.svg'),
    dark: nextAsset('images/music-services/pandora-color-dark.svg')
  },
  logoColorPadding: {
    light: nextAsset('images/music-services/pandora-color-light-padding.svg'),
    dark: nextAsset('images/music-services/pandora-color-dark-padding.svg')
  },
  logoOnBackground: nextAsset('images/music-services/pandora-dark.svg'),
  whatIsDescription
};
