import { MusicService } from './types';
import { nextAsset } from '../../nextAsset';

export const fileBackup: MusicService = {
  id: 'file-backup-excel-xlsx',
  appId: 'file-backup',
  shortName: 'File Backup',
  name: 'File Backup (Excel/XLSX)',
  isComingSoon: false,
  areStatsSupported: false,
  isAlbumTransferSupported: true,
  isSupported: true,
  color: '#6417fb',
  logo: {
    light: nextAsset('images/music-services/file-light.svg'),
    dark: nextAsset('images/music-services/file-dark.svg')
  },
  logoPadding: {
    light: nextAsset('images/music-services/file-light-padding.svg'),
    dark: nextAsset('images/music-services/file-dark-padding.svg')
  },
  logoColor: {
    light: nextAsset('images/music-services/file-color-light.svg'),
    dark: nextAsset('images/music-services/file-color-dark.svg')
  },
  logoColorPadding: {
    light: nextAsset('images/music-services/file-color-light-padding.svg'),
    dark: nextAsset('images/music-services/file-color-dark-padding.svg')
  },
  logoOnBackground: nextAsset('images/music-services/file-color-dark.svg')
};
