import { defineMessages } from 'react-intl';
import { MusicService } from './types';
import { nextAsset } from '../../nextAsset';

const { whatIsDescription } = defineMessages({
  whatIsDescription: {
    id: 'web.music-service.what-is.jiosaavn',
    defaultMessage:
      'JioSaavn is one of India’s biggest music streaming services, with over 43 million songs. It offers a vast library of Bollywood, regional Indian music, and international hits. You can enjoy exclusive content, podcasts, and high-quality audio with a premium subscription.'
  }
});
export const jiosaavn: MusicService = {
  id: 'jiosaavn',
  shortName: 'JioSaavn',
  name: 'JioSaavn',
  isComingSoon: false,
  areStatsSupported: true,
  isAlbumTransferSupported: false,
  isSupported: true,
  color: '#2bc5b4',
  logo: {
    light: nextAsset('images/music-services/jiosaavn-light.svg'),
    dark: nextAsset('images/music-services/jiosaavn-dark.svg')
  },
  logoPadding: {
    light: nextAsset('images/music-services/jiosaavn-light-padding.svg'),
    dark: nextAsset('images/music-services/jiosaavn-dark-padding.svg')
  },
  logoColor: {
    light: nextAsset('images/music-services/jiosaavn-color-light.svg'),
    dark: nextAsset('images/music-services/jiosaavn-color-dark.svg')
  },
  logoColorPadding: {
    light: nextAsset('images/music-services/jiosaavn-color-light-padding.svg'),
    dark: nextAsset('images/music-services/jiosaavn-color-dark-padding.svg')
  },
  logoOnBackground: nextAsset('images/music-services/jiosaavn-color-dark.svg'),
  whatIsDescription
};
