import { MusicService } from './types';
import { nextAsset } from '../../nextAsset';

export const resso: MusicService = {
  id: 'resso',
  shortName: 'Resso',
  name: 'Resso',
  isComingSoon: false,
  areStatsSupported: true,
  isAlbumTransferSupported: true,
  isSupported: false,
  color: '#FD0164',
  logo: {
    light: nextAsset('images/music-services/resso-light.svg'),
    dark: nextAsset('images/music-services/resso-dark.svg')
  },
  logoPadding: {
    light: nextAsset('images/music-services/resso-light-padding.svg'),
    dark: nextAsset('images/music-services/resso-dark-padding.svg')
  },
  logoColor: {
    light: nextAsset('images/music-services/resso-color-light.svg'),
    dark: nextAsset('images/music-services/resso-color-dark.svg')
  },
  logoColorPadding: {
    light: nextAsset('images/music-services/resso-color-light-padding.svg'),
    dark: nextAsset('images/music-services/resso-color-dark-padding.svg')
  },
  logoOnBackground: nextAsset('images/music-services/resso-dark.svg')
};
