import { defineMessages } from 'react-intl';
import { MusicService } from './types';
import { nextAsset } from '../../nextAsset';

const { whatIsDescription } = defineMessages({
  whatIsDescription: {
    id: 'web.music-service.what-is.boomplay',
    defaultMessage:
      'Boomplay is a music streaming service popular in Africa, offering over 90 million songs, music videos, and podcasts. Explore local and international hits with free and premium plans.'
  }
});
export const boomplay: MusicService = {
  id: 'boomplay',
  shortName: 'Boomplay',
  name: 'Boomplay',
  isComingSoon: false,
  areStatsSupported: true,
  isAlbumTransferSupported: true,
  isSupported: true,
  color: '#00FFFF',
  textColor: '#00a6a6',
  logo: {
    light: nextAsset('images/music-services/boomplay-light.svg'),
    dark: nextAsset('images/music-services/boomplay-dark.svg')
  },
  logoPadding: {
    light: nextAsset('images/music-services/boomplay-light-padding.svg'),
    dark: nextAsset('images/music-services/boomplay-dark-padding.svg')
  },
  logoColor: {
    light: nextAsset('images/music-services/boomplay-color-light.svg'),
    dark: nextAsset('images/music-services/boomplay-color-dark.svg')
  },
  logoColorPadding: {
    light: nextAsset('images/music-services/boomplay-color-light-padding.svg'),
    dark: nextAsset('images/music-services/boomplay-color-dark-padding.svg')
  },
  logoOnBackground: nextAsset('images/music-services/boomplay-dark.svg'),
  whatIsDescription
};
