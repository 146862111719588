import { spotify } from './services/Spotify';
import { appleMusic } from './services/AppleMusic';
import { MigrationOption, MusicServices } from './services/types';
import { amazonMusic } from './services/AmazonMusic';
import { deezer } from './services/Deezer';
import { pandora } from './services/Pandora';
import { tidal } from './services/Tidal';
import { youtube } from './services/YouTube';
import { soundcloud } from './services/SoundCloud';
import { qobuz } from './services/Qobuz';
import { qqMusic } from './services/QQMusic';
import { yandexMusic } from './services/YandexMusic';
import { anghami } from './services/Anghami';
import { youtubeMusic } from './services/YouTubeMusic';
import { napster } from './services/Napster';
import { vkMusic } from './services/VKMusic';
import { zvuk } from './services/Zvuk';
import { gaana } from './services/Gaana';
import { jiosaavn } from './services/JioSaavn';
import { fileBackup } from './services/FileBackup';
import { resso } from './services/Resso';
import { boomplay } from './services/Boomplay';

export const musicServices = [
  amazonMusic,
  appleMusic,
  deezer,
  pandora,
  soundcloud,
  spotify,
  tidal,
  youtube,
  youtubeMusic,
  napster,
  qobuz,
  qqMusic,
  yandexMusic,
  vkMusic,
  anghami,
  zvuk,
  gaana,
  jiosaavn,
  resso,
  boomplay,
  fileBackup
];
export const supportedMusicServices = musicServices.filter((music) => music.isSupported);
export const reversedSupportedMusicServices = supportedMusicServices.slice().reverse(); // BH: fucking JS... reverse also changes original array, so we need slice() to make a copy/clone

export const musicServicesByID: MusicServices = {};
musicServices.forEach((ms) => {
  musicServicesByID[ms.id] = ms;
  if (ms.appId) {
    musicServicesByID[ms.appId] = ms;
  }
});

export const musicServicesForStats = supportedMusicServices.filter((ms) => ms.areStatsSupported);

export const permutatedMusicServices = ((): MigrationOption[][] => {
  return supportedMusicServices.map((d) =>
    supportedMusicServices.map((v) => {
      return {
        from: d,
        to: v
      };
    })
  );
})();
